import React, { Fragment } from 'react'

import SEO from '../components/seo'
import Layout from '../components/layout'
import Card from '../components/Card'

const documenti = [
  {
    id: 1,
    nome: 'Codice Etico',
    descrizione: 'Codice etico Mediterranea Express.',
    linkUrl: '/codice_etico.pdf',
  },
  {
    id: 2,
    nome: 'Procedura whistleblowing',
    descrizione:
      "Procedura per regolamentare  la ricezione, l'analisi e il trattamento delle segnalazioni di irregolarità.",
    linkUrl: '/procedura_whistleblowing.pdf',
  },
  {
    id: 11,
    nome: 'Modello 231 - parte generale',
    descrizione: 'Parte generale del modello 231 Mediterranea Express.',
    linkUrl: '/parte_generale_modello_231.pdf',
  },
  // {
  //   id: 112,
  //   nome: 'Modello 231 - parte speciale',
  //   descrizione: 'Parte speciale del modello 231 Mediterranea Express.',
  //   linkUrl: '/parte_speciale_modello_231.pdf',
  // },
  {
    id: 12,
    nome: 'Modello 231 - codice sanzioni',
    descrizione: 'Codice sanzioni del modello 231 Mediterranea Express.',
    linkUrl: '/codice_sanzioni_modello_231.pdf',
  },
  // {
  //   id: 2,
  //   nome: "Modello 231 - regolamento di funzionamento dell'OdV",
  //   descrizione: "Regolamento di funzionamento dell'organismo di vigilanza del modello 231 Mediterranea Express.",
  //   linkUrl: '/regolamento_funzionamento_odv_modello_231.pdf',
  // },
  // {
  //   id: 21,
  //   nome: 'Modello 231 - mappatura dei rischi',
  //   descrizione: 'Mappatura dei rischi del modello 231 Mediterranea Express.',
  //   linkUrl: '/matrice_rischi_modello_231.pdf',
  // },
  // {
  //   id: 2,
  //   nome: 'Anti Corruzione',
  //   descrizione: 'Linee Guida Anti Corruzione.',
  //   linkUrl: '/anticorruzione.pdf',
  // },
  // {
  //   id: 3,
  //   nome:
  //     'Documentazione accreditamento fornitori servizi di magazzino e trasporto',
  //   descrizione: 'Capitolato Generale Subappalto Parziale Lavori e Servizi.',
  //   linkUrl: '/documentazione_accreditamento_fornitori.pdf',
  // },
  // {
  //   id: 4,
  //   nome: 'DUVRI Catania',
  //   descrizione:
  //     'Documento Unico di Valutazione dei Rischi da Interferenza (DUVRI) della sede GLS di Catania.',
  //   linkUrl: '/duvri_catania.pdf',
  // },
  // {
  //   id: 5,
  //   nome: 'DUVRI Messina',
  //   descrizione:
  //     'Documento Unico di Valutazione dei Rischi da Interferenza (DUVRI) della sede GLS di Messina.',
  //   linkUrl: '/duvri_messina.pdf',
  // },
  // {
  //   id: 6,
  //   nome: 'DUVRI Milazzo',
  //   descrizione:
  //     'Documento Unico di Valutazione dei Rischi da Interferenza (DUVRI) della sede GLS di Milazzo.',
  //   linkUrl: '/duvri_milazzo.pdf',
  // },
  // {
  //   id: 7,
  //   nome: 'DUVRI Ragusa',
  //   descrizione:
  //     'Documento Unico di Valutazione dei Rischi da Interferenza (DUVRI) della sede GLS di Ragusa.',
  //   linkUrl: '/duvri_ragusa.pdf',
  // },
  // {
  //   id: 8,
  //   nome: 'DUVRI Siracusa',
  //   descrizione:
  //     'Documento Unico di Valutazione dei Rischi da Interferenza (DUVRI) della sede GLS di Siracusa.',
  //   linkUrl: '/duvri_siracusa.pdf',
  // },
  // {
  //   id: 9,
  //   nome: 'DUVRI Belpasso',
  //   descrizione:
  //     'Documento Unico di Valutazione dei Rischi da Interferenza (DUVRI) della sede GLS di Belpasso.',
  //   linkUrl: '/duvri_belpasso.pdf',
  // },
]

const CorporateGovernance = () => {
  return (
    <Layout>
      <SEO
        title="Corporate governance"
        description="Documenti corporate governance"
      />
      <section className="py-8 px-6">
        <h1 className="text-3xl sm:text-4xl font-light text-gls-blue-900 leading-tight">
          Corporate Governance
        </h1>
      </section>
      <section className="bg-white lg:rounded-lg lg:shadow-xl">
        <div className="flex flex-wrap py-12 px-6">
          {documenti.map((doc) => {
            return (
              <Fragment key={doc.id}>
                <Card oggetto={doc} externalLink />
              </Fragment>
            )
          })}
        </div>
      </section>
    </Layout>
  )
}

export default CorporateGovernance
